export const create = {
  REQUEST: 'CREATE_PROJECT_REQUEST',
  SUCCESS: 'CREATE_PROJECT_SUCCESS',
  FAIL: 'CREATE_PROJECT_FAIL'
}

export const getById = {
  REQUEST: 'GET_PROJECT_BY_ID_REQUEST',
  SUCCESS: 'GET_PROJECT_BY_ID_SUCCESS',
  FAIL: 'GET_PROJECT_BY_ID_FAIL'
}

export const get = {
  REQUEST: 'GET_PROJECTS_REQUEST',
  SUCCESS: 'GET_PROJECTS_SUCCESS',
  FAIL: 'GET_PROJECTS_FAIL'
}

export const getAndConcat = {
  REQUEST: 'GET_PROJECTS_AND_CONCAT_REQUEST',
  SUCCESS: 'GET_PROJECTS_AND_CONCAT_SUCCESS',
  FAIL: 'GET_PROJECTS_AND_CONCAT_FAIL'
}

export const update = {
  REQUEST: 'UPDATE_PROJECT_REQUEST',
  SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  FAIL: 'UPDATE_PROJECT_FAIL'
}


