/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/styles/global.css"

// You can delete this file if you're not using it
import Provider from "./src/reducers/provider"
export const wrapRootElement = Provider
