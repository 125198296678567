// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-end-forgot-password-js": () => import("./../../../src/pages/end-forgot-password.js" /* webpackChunkName: "component---src-pages-end-forgot-password-js" */),
  "component---src-pages-end-register-js": () => import("./../../../src/pages/end-register.js" /* webpackChunkName: "component---src-pages-end-register-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-project-index-js": () => import("./../../../src/pages/new-project/index.js" /* webpackChunkName: "component---src-pages-new-project-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-project-details-index-js": () => import("./../../../src/pages/project-details/index.js" /* webpackChunkName: "component---src-pages-project-details-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-proposal-index-js": () => import("./../../../src/pages/proposal/index.js" /* webpackChunkName: "component---src-pages-proposal-index-js" */),
  "component---src-pages-provider-index-js": () => import("./../../../src/pages/provider/index.js" /* webpackChunkName: "component---src-pages-provider-index-js" */),
  "component---src-pages-provider-profile-index-js": () => import("./../../../src/pages/provider-profile/index.js" /* webpackChunkName: "component---src-pages-provider-profile-index-js" */),
  "component---src-pages-provider-project-detail-js": () => import("./../../../src/pages/provider-project-detail.js" /* webpackChunkName: "component---src-pages-provider-project-detail-js" */),
  "component---src-pages-providers-index-js": () => import("./../../../src/pages/providers/index.js" /* webpackChunkName: "component---src-pages-providers-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

