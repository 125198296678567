export const register = {
  REQUEST: 'REGISTER_REQUEST',
  SUCCESS: 'REGISTER_SUCCESS',
  FAIL: 'REGISTER_FAIL',
  CLEAN: 'CLEAN_REGISTER'
}

export const login = {
  REQUEST: 'LOGIN_REQUEST',
  SUCCESS: 'LOGIN_SUCCESS',
  FAIL: 'LOGIN_FAIL'
}

export const update = {
  REQUEST: 'UPDATE_USER_REQUEST',
  SUCCESS: 'UPDATE_USER_SUCCESS',
  FAIL: 'UPDATE_USER_FAIL'
}

export const logout = {
  REQUEST: 'LOGOUT_REQUEST',
  SUCCESS: 'LOGOUT_SUCCESS',
  FAIL: 'LOGOUT_FAIL'
}

export const get = {
  REQUEST: 'GET_USERS_REQUEST',
  SUCCESS: 'GET_USERS_SUCCESS',
  FAIL: 'GET_USERS_FAIL',
}

export const getById = {
  REQUEST: 'GET_USER_BY_ID_REQUEST',
  SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  FAIL: 'GET_USER_BY_ID_FAIL',
}

export const getAndConcat = {
  REQUEST: 'GET_USERS_AND_CONCAT_REQUEST',
  SUCCESS: 'GET_USERS_AND_CONCAT_SUCCESS',
  FAIL: 'GET_USERS_AND_CONCAT_FAIL',
}
