import { create, getById, get, getAndConcat, update } from './types/project.js'
import { state as initialState } from './states/project.js'

export default (state = initialState, action) => {
  switch (action.type) {
    case create.REQUEST:
      return { 
        ...state,
        createProject: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case create.SUCCESS:
      return { 
        ...state,
        createProject: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case create.FAIL:
      return { 
        ...state,
        createProject: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al crear el proyecto, por favor intente más tarde'
        }
      }
    case update.REQUEST:
      return { 
        ...state,
        updateProject: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case update.SUCCESS:
      return { 
        ...state,
        updateProject: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case update.FAIL:
      return { 
        ...state,
        updateProject: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al guardar el proyecto, por favor intente más tarde'
        }
      }
    case getById.REQUEST:
      return { 
        ...state,
        getProjectById: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case getById.SUCCESS:
      return { 
        ...state,
        getProjectById: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case getById.FAIL:
      return { 
        ...state,
        getProjectById: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener el proyecto, por favor intente más tarde'
        }
      }
    case get.REQUEST:
      return { 
        ...state,
        getProjects: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case get.SUCCESS:
      return { 
        ...state,
        getProjects: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case get.FAIL:
      return { 
        ...state,
        getProjects: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener los proyectos, por favor intente más tarde'
        }
      }
    case getAndConcat.REQUEST:
      return { 
        ...state,
        getProjects: {
          ...state.getProjects,
          loading: true,
          errorMsg: ''
        }
      }
    case getAndConcat.SUCCESS:
      const data = {
        ...action.data,
        docs: [
          ...state.getProjects.response.docs,
          ...action.data.docs
        ]
      }
      return { 
        ...state,
        getProjects: {
          loading: false,
          response: data,
          errorMsg: ''
        }
      }
    case getAndConcat.FAIL:
      return { 
        ...state,
        getProjects: {
          ...state.getProjects,
          loading: false,
          errorMsg: 'Problemas al obtener los proyectos restantes, por favor intente más tarde'
        }
      }
    default:
      return state
  }
};
