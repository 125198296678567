export const state = {
  register: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  login: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  updateUser: {
    loading: false,
    errorMsg: ''
  },
  getUserById: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  getUsers: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  logout: {
    loading: false,
    errorMsg: ''
  }
}
